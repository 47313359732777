import { useEffect, useState } from 'react';

interface TypeWriterProps {
  words: string[];
  delay?: number;
  deleteDelay?: number;
}

export const TypeWriter = ({ 
  words, 
  delay = 50,
  deleteDelay = 70 
}: TypeWriterProps) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      const currentWord = words[currentIndex];
      
      if (isDeleting) {
        setCurrentText(currentWord.substring(0, currentText.length - 1));
        if (currentText === '') {
          setIsDeleting(false);
          setCurrentIndex((prev) => (prev + 1) % words.length);
        }
      } else {
        setCurrentText(currentWord.substring(0, currentText.length + 1));
        if (currentText === currentWord) {
          setTimeout(() => setIsDeleting(true), 1500);
        }
      }
    }, isDeleting ? deleteDelay : delay);

    return () => clearTimeout(timer);
  }, [currentText, currentIndex, isDeleting, words, delay, deleteDelay]);

  return (
    <span
      className="bg-gradient-to-r from-[#387DFD] to-[#54DFFF] bg-clip-text text-transparent"
      style={{
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      }}
    >
      {currentText}
    </span>
  );
};