/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from '@tanstack/react-query';

import * as ProductsApi from './Products/Products';

export const getProductList = (currency = 'PHP', condition = true) => {
  return useQuery({
    queryKey: ['productList'],
    queryFn: () => ProductsApi.getProductList(currency),
    enabled: condition,
  });
};
