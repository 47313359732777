export enum DLLFormat {
  KINDER = 'KINDER',
  GRADE = 'GRADE',
  MATATAG = 'MATATAG',
  ALS = 'ALS',
  STANDARD = 'STANDARD',
}

export const DLL_FORMAT = ['KINDER', 'GRADE', 'MATATAG', 'ALS', 'STANDARD'];
export const DLL_ENABLED_FORMATS = ['STANDARD'];
