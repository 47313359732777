/* eslint-disable react-hooks/rules-of-hooks */
import { UseMutationResult, useMutation } from '@tanstack/react-query';

import * as GeolocationApi from './Geolocation/index';

export const getGeolocation = (
  onSuccess,
): UseMutationResult<unknown, unknown, string> => {
  return useMutation((ip) => GeolocationApi.getUserCountry(ip), {
    onSuccess,
  });
};
