import clsx from 'clsx';

import { Product } from '../types/product';

import PremiumIcon from './PremiumIcon';

interface PricingTierCardProps {
  tier: Product;
  currency: 'PHP' | 'USD';
  handleSelectPlan: (tier: Product) => void;
}

const PricingTierCard: React.FC<PricingTierCardProps> = ({
  tier,
  currency,
  handleSelectPlan,
}: PricingTierCardProps) => {
  const currencySymbol = currency === 'PHP' ? '₱' : '$';
  return (
    <div
      key={tier.id}
      className={clsx(
        tier.isHightlighted ? 'ring-2 ring-blue-600' : 'ring-1 ring-gray-200',
        'rounded-3xl p-8 xl:p-10',
      )}
    >
      <div className="mb-4 flex items-center justify-between gap-x-4">
        <h2
          id={tier.id}
          className={clsx(
            tier.isHightlighted ? 'text-blue-600' : 'text-gray-900',
            'text-xl font-semibold leading-8',
          )}
        >
          {tier.name}
        </h2>
        {tier.tagText ? (
          <p className="font-sm rounded-full bg-blue-600/10 px-2.5 py-1 text-sm leading-5 text-blue-600">
            {tier.tagText}
          </p>
        ) : null}
      </div>
      <span className="text-5xl font-bold tracking-tight text-gray-900">
        {currencySymbol}
        {currency === 'PHP' ? tier.price : tier.internationalPrice}
      </span>
      <div className="mt-2 flex items-center text-lg leading-6 text-gray-600">
        <PremiumIcon className="h-7 fill-[#FFB743]" />
        {tier.description}
      </div>
      <button
        aria-describedby={tier.id}
        className={clsx(
          tier.isHightlighted
            ? 'bg-blue-600 text-white shadow-sm hover:bg-blue-500'
            : 'text-blue-600 ring-1 ring-inset ring-blue-200 hover:ring-blue-300',
          'text-md mt-6 block rounded-md py-3 px-4 text-center font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:text-lg',
        )}
        onClick={() => handleSelectPlan(tier)}
      >
        Top-up credits
      </button>
    </div>
  );
};

export default PricingTierCard;
