import { createContext } from 'react';

import { UserCreditType } from '../../api/external/Credits';

import { CreditDispatchEvent } from './enum';
import { InitialStateType } from './provider';

export interface CreditContextType {
  context: InitialStateType;
  dispatch: <T extends UserCreditType>(
    actionType: CreditDispatchEvent,
    payload?: T | undefined,
  ) => void;
}

const CreditContext = createContext({} as CreditContextType);

export default CreditContext;
