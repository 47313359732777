import { Features } from '@lessonPlanner/constants';

export const routes = {
  LEARNING_COMPETENCY: '/objective',
  LANDING: '/',
  MAIN: '/lesson-plan',
  PAYMENT: '/payment',
  PRICING: '/pricing',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_AND_CONDITIONS: '/terms-of-service-agreement',
  QUIZMAKER: '/quizmaker',
  ACTIVITIES: '/activities',
  SUBJECT_EXTENSIONS: '/cross-subject-extension',
  ANALOGY: '/analogy',
  DAILY_LESSON_LOG: '/daily-lesson-log',
  CATCH_UP_FRIDAYS: '/catch-up-fridays',
  SUMMATIVE_TEST: '/summative-test',
  CEREBRO_LESSON_PLAN: '/cerebro-lesson-plan',
  SCHOOL_CONTACT: '/school-contact',
  V2_LOGIN: '/v2',
  V2_DASHBOARD: '/v2/dashboard',
  V2_LESSON_PLANNER: '/v2/lesson-planner',
  V2_SHORT_QUIZ: '/v2/short-quiz',
  V2_ACTIVITY: '/v2/activity',
  V2_CATCH_UP_FRIDAYS: '/v2/catch-up-fridays',
  V2_HISTORY: '/v2/history',
  V2_OBJECTIVES: '/v2/objectives',
  V2_SUMMATIVE_TEST: '/v2/summative-test',
  DLL: '/dll',
  BLOG: '/blog',
  TOPIC_EXPLORER: '/topic-explorer',
  ENERGIZER: '/energizer',
  ELIF: '/elif',
};

export const mainRoutes = [
  {
    link: routes.DLL,
    label: 'DLL',
    featureKey: Features.DLL,
  },
  {
    link: routes.SUMMATIVE_TEST,
    label: 'Summative Test',
    featureKey: Features.SUMMATIVE_TEST,
  },
  {
    label: 'Topic Explorer',
    link: routes.TOPIC_EXPLORER,
    featureKey: Features.TOPIC_EXPLORER,
  },
  {
    link: routes.ACTIVITIES,
    label: 'Activity',
    featureKey: Features.ACTIVITIES,
  },
  {
    link: routes.QUIZMAKER,
    label: 'Short Quiz',
    featureKey: Features.QUIZMAKER,
  },
  {
    link: routes.LEARNING_COMPETENCY,
    label: 'Objectives',
    featureKey: Features.OBJECTIVES,
  },
  {
    link: routes.MAIN,
    label: 'Lesson Plan',
    featureKey: Features.LESSON_PLAN,
  },
];

export const defaultMoreApps = [
  ...mainRoutes.filter((route) => route.featureKey !== Features.LESSON_PLAN),
  {
    label: 'Catch-Up Fridays',
    link: routes.CATCH_UP_FRIDAYS,
    featureKey: Features.CATCH_UP_FRIDAYS,
  },
  {
    label: 'Learning Within and Across the Curriculum',
    link: routes.SUBJECT_EXTENSIONS,
    featureKey: Features.SUBJECT_EXTENSIONS,
  },
  {
    label: 'Analogy',
    link: routes.ANALOGY,
    featureKey: Features.ANALOGY,
  },
];

export const internationalRoutes = [
  {
    label: 'Analogy',
    link: routes.ANALOGY,
    featureKey: Features.ANALOGY,
  },
  ...mainRoutes.filter(
    (route) =>
      route.featureKey !== Features.DLL &&
      route.featureKey !== Features.OBJECTIVES,
  ),
];

export const latestApps = [
  {
    label: 'Learning Within and Across the Curriculum',
    link: routes.SUBJECT_EXTENSIONS,
    featureKey: Features.SUBJECT_EXTENSIONS,
  },
  {
    label: 'Analogy',
    link: routes.ANALOGY,
    featureKey: Features.ANALOGY,
  },
];

export const mobileRoutes = [
  {
    link: routes.MAIN,
    label: 'Lesson Plan',
    featureKey: Features.LESSON_PLAN,
  },
  {
    link: routes.DLL,
    label: 'DLL',
    featureKey: Features.DLL,
  },
  {
    link: routes.SUMMATIVE_TEST,
    label: 'Summative Test',
    featureKey: Features.SUMMATIVE_TEST,
  },
  {
    label: 'Topic Explorer',
    link: routes.TOPIC_EXPLORER,
    featureKey: Features.TOPIC_EXPLORER,
  },
  {
    link: routes.ENERGIZER,
    label: 'Energizer',
    featureKey: Features.ENERGIZER,
  },
  {
    link: routes.ELIF,
    label: 'ELI5',
    featureKey: Features.ELIF,
  },
  {
    link: routes.CATCH_UP_FRIDAYS,
    label: 'Catch-Up Fridays',
    featureKey: Features.CATCH_UP_FRIDAYS,
  },
  {
    link: routes.LEARNING_COMPETENCY,
    label: 'Objectives',
    featureKey: Features.OBJECTIVES,
  },
  {
    link: routes.QUIZMAKER,
    label: 'Short Quiz',
    featureKey: Features.QUIZMAKER,
  },
  {
    link: routes.ACTIVITIES,
    label: 'Activity',
    featureKey: Features.ACTIVITIES,
  },
  ...latestApps,
];

export const premiumRoutes = [
  routes.MAIN,
  routes.SUMMATIVE_TEST,
  routes.DLL,
  routes.TOPIC_EXPLORER,
];

export const currencyMap = {
  PH: 'PHP',
  US: 'USD',
};
