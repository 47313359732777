export const landingToolCards = [
  {
    title: 'Lesson Plan',
    description:
      'Craft comprehensive lesson plans in minutes, tailored to your grade level, subject, and objectives.',
    color: 'linear-gradient(110deg, #387DFD 0%, #54DFFF 100%)',
    label: 'Crowd Favorite',
  },
  {
    title: 'Catch Up Fridays',
    description:
      'Address learning gaps and reinforce concepts in dedicated sessions that take just moments to plan.',
    color: 'linear-gradient(110deg, #FD6738 0%, #FF54F8 100%)',
  },
  {
    title: 'Objectives',
    description:
      "Break down learning objectives into a week's worth of classes, ensuring alignment with cognitive, affective, and psychomotor learning domains.",
    color: 'linear-gradient(110deg, #D7BF7F 0%, #FF5454 100%)',
  },
  {
    title: 'Summative Test',
    description:
      'Evaluate student understanding and progress through summative assessments.',
    color: 'linear-gradient(110deg, #9DB655 0%, #55E698 100%)',
  },
];

export const landingTypedWords = [
  'Lesson Plan',
  'Activity',
  'Assessment',
  'Objective',
];
