export const testimonials = [
  {
    body: '"Lesson Planner helped me so much in my teaching career. It made my work easier and credible in crafting my lesson plan every week/month."',
    image: '/images/landing/testimonials/sherilyn.jpg',
    name: 'Sherilyn Tampus',
    position: 'MAPEH JHS Teacher',
  },
  {
    body: '"Lesson Planner is very helpful to me. It makes my tasks very easy. As a teacher, I have a lot of things to do-- not only my day to day tasks, but also other tasks mandated by DepEd. Lesson Planner is the best. I can say that it is my buddy in teaching."',
    image: '/images/landing/testimonials/remelita.jpg',
    name: 'Remelita Macatangay',
    position: 'Elementary Teacher',
  },
  {
    body: '“Lesson planning demands extensive preparation time, and teachers often find themselves juggling numerous tasks. Lesson Planner has been invaluable to me, significantly streamlining my workload and accelerating the planning process. As a woman, a mother and a teacher with many responsibilities in both my community and my family, I am grateful for its assistance as it enables me to utilize my time more efficiently for other tasks.”',
    image: '/images/landing/testimonials/bernadette.jpg',
    name: 'Bernadette Cardeno',
    position: 'Elementary Teacher',
  },
  {
    body: '"Lesson Planner can help educators plan their lessons more efficiently, facilitate collaboration among educators, serve as a tool for professional development, offering resources, templates, and guidelines for effective lesson planning, help elevate women in the education sector that promote gender equality, diversity, and inclusion in the classroom, contributing to a more equitable education system."',
    image: '/images/landing/testimonials/kristina.jpg',
    name: 'Ma Kristina T. Buenaventura',
    position: 'Junior Highschool Teacher',
  },
  {
    body: '"Lesson Planner helped me to explore more and develop my ideas when lesson planning, making everything smooth and easy."',
    image: '/images/landing/testimonials/yrich.jpg',
    name: 'Yrich Cloma',
    position: 'SHS Teacher',
  },
];
