import { ReactNode, useEffect, useState } from 'react';

import Cookies from 'js-cookie';

import { Features } from '@lessonPlanner/constants';

import { getGeolocation } from '../api/external/Geolocation';

import AnnouncementBanner from './AnnouncementBanner';

interface Props {
  children: ReactNode;
}

function MainLayout(props: Props) {
  const { children } = props;
  const [ip, setIp] = useState<string>();
  const hasCountryCookie = Cookies.get('country') !== undefined;
  const { isLoading, mutateAsync } = getGeolocation(async (res) => {
    Cookies.set('country', res.country);
  });

  useEffect(() => {
    if (!hasCountryCookie) {
      const getUserIp = async () => {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setIp(data.ip);
      };
      getUserIp();
    }
  }, [hasCountryCookie]);

  useEffect(() => {
    if (ip && !hasCountryCookie) {
      console.log('User IP:', ip);
      mutateAsync(ip);
      // posthog.identify(ip);
    }
  }, [ip, hasCountryCookie]);
  return (
    <div className="relative">
      <AnnouncementBanner featureFlag={Features.ANNOUNCEMENT_BANNER} />
      {children}
    </div>
  );
}

export default MainLayout;
