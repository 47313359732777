import { request } from '../../../utils/fetch';

async function getUserCountry(ip: string) {
  // const url = `https://ipinfo.io/${ip}?token=${process.env.NX_GEOLOCATION_API_KEY}`;

  // const response = await fetch(url);
  const url = new URL(
    `/api/v1/geolocation/${ip}`,
    process.env.NX_EXTERNAL_BACKEND_URL,
  );

  const response = await request(url.toString(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    const data = await response.json();
    console.error(data);
    throw new Error('Something went wrong.');
  }

  return await response.json();
}

export { getUserCountry };
